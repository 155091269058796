<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Seleccionar almacen') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px; margin-bottom: 100px">
        <template v-if="data?.length">
          <ion-item
            v-for="(almacen, key) in data"
            :key="`material-almacen-${key}`"
            @click="$emit('done', almacen)"
          >
            <ion-label class="ion-text-wrap">
              <p>{{ almacen?.nombre || 'Sin nombre' }}</p>
            </ion-label>
            <ion-label slot="end" class="ion-text-end">
              <div
                v-if="Array.isArray(almacen?.articulos) && almacen.articulos.length > 0"
                :class="{ 'text-red': stockTotal(almacen) < 0 }"
              >
                {{ stockTotal(almacen) === 0 ? $t('Sin stock') : stockTotal(almacen) }}
              </div>
              <div v-else>
                {{ $t('Sin stock') }}
              </div>
            </ion-label>
          </ion-item>
        </template>
        <template v-else>
          <ion-label>
            {{ $t('Sin almacenes') }}
          </ion-label>
        </template>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
} from '@ionic/vue';

export default {
  name: 'MSetMaterialAlmacen',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
  },

  methods: {
    stockTotal(almacen) {
      if (!almacen || !Array.isArray(almacen.articulos)) {
        return 0; // Evita errores si `almacen` o `articulos` es `null` o `undefined`
      }
      return almacen.articulos.reduce((total, articulo) => total + (articulo.pivot?.stock || 0), 0);
    }
  },
};
</script>

<style>
.text-red {
  color: red;
}
</style>